
import { IconProps } from './types';

const SpecialPriceIcon = ({ isWhiteColor }: IconProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="43" height="60" viewBox="0 0 43 60" fill="none">
            <g clipPath="url(#clip0_11_17376)">
                <path
                    d="M3.90898 30.7697H31.2718V7.38735H3.90898V30.7697ZM16.6132 11.2856V13.2341H14.6587V9.33709H20.5221V13.2341H18.5677V11.2856H16.6132ZM5.86347 9.33588H12.7042V15.1815H22.4766V9.33588H29.3174V28.8211H5.86347V9.33588Z"
                    fill={isWhiteColor ? 'white' : "url(#paint0_linear_11_17376)"}
                />
                <path d="M21.4994 24.9253H27.3629V26.8738H21.4994V24.9253Z" fill={isWhiteColor ? 'white' : "url(#paint1_linear_11_17376)"} />
                <path d="M21.4994 21.0283H27.3629V22.9768H21.4994V21.0283Z" fill={isWhiteColor ? 'white' : "url(#paint2_linear_11_17376)"} />
                <path
                    d="M5.91477 37.2388L4.60038 35.9284L3.2188 37.3058L5.81216 39.8924L13.3394 33.4599L12.0678 31.9814L5.91355 37.24L5.91477 37.2388Z"
                    fill={isWhiteColor ? 'white' : "url(#paint3_linear_11_17376)"}
                />
                <path d="M15.6359 32.7194H31.2718V34.6679H15.6359V32.7194Z" fill={isWhiteColor ? 'white' : "url(#paint4_linear_11_17376)"} />
                <path d="M29.3173 36.6165H31.2718V38.565H29.3173V36.6165Z" fill={isWhiteColor ? 'white' : "url(#paint5_linear_11_17376)"} />
                <path d="M15.6359 36.6165H27.3629V38.565H15.6359V36.6165Z" fill={isWhiteColor ? 'white' : "url(#paint6_linear_11_17376)"} />
                <path
                    d="M5.91477 46.9814L4.60038 45.671L3.2188 47.0484L5.81216 49.6351L13.3394 43.2025L12.0678 41.724L5.91355 46.9826L5.91477 46.9814Z"
                    fill={isWhiteColor ? 'white' : "url(#paint7_linear_11_17376)"}
                />
                <path d="M15.6359 42.462H31.2718V44.4106H15.6359V42.462Z" fill={isWhiteColor ? 'white' : "url(#paint8_linear_11_17376)"} />
                <path d="M29.3173 46.3591H31.2718V48.3076H29.3173V46.3591Z" fill={isWhiteColor ? 'white' : "url(#paint9_linear_11_17376)"} />
                <path d="M15.6359 46.3591H27.3629V48.3076H15.6359V46.3591Z" fill={isWhiteColor ? 'white' : "url(#paint10_linear_11_17376)"} />
                <path
                    d="M35.1735 50.2574C35.1601 46.9558 35.171 36.815 35.1808 26.9676C35.1918 15.1668 35.204 2.96298 35.1808 1.52594L35.1539 0.0133961L30.1578 2.38329L26.9622 0.394577L23.8338 2.3431L20.7066 0.394577L17.5806 2.3431L14.4571 0.394577L11.3324 2.3431L8.20642 0.394577L5.01571 2.38329L0 0V55.1275C0 57.814 2.19147 59.9988 4.88622 59.9988H38.1138C40.8085 59.9988 43 57.814 43 55.1275V50.2561H35.1747L35.1735 50.2574ZM7.81796 55.1275C7.81796 56.7387 6.50234 58.0503 4.88622 58.0503C3.27011 58.0503 1.95449 56.7387 1.95449 55.1275V3.08598L5.14275 4.59974L8.20519 2.6914L11.3312 4.63993L14.4559 2.6914L17.5794 4.63993L20.7054 2.6914L23.8326 4.63993L26.961 2.6914L30.0283 4.59974L33.2349 3.07989C33.2471 6.97085 33.2349 17.1068 33.2263 26.9652C33.2153 37.2254 33.2056 46.9887 33.219 50.2561H7.81796V55.1275ZM41.0443 55.1275C41.0443 56.7387 39.7287 58.0503 38.1126 58.0503H8.79276C9.4072 57.2355 9.77245 56.2235 9.77245 55.1275V52.2047H41.0443V55.1275Z"
                    fill={isWhiteColor ? 'white' : "url(#paint11_linear_11_17376)"}
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_11_17376"
                    x1="37.0875"
                    y1="-20.0496"
                    x2="-11.3603"
                    y2="52.8037"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#1341B0" />
                    <stop offset="1" stopColor="#3AC0C1" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_11_17376"
                    x1="37.0875"
                    y1="-20.0496"
                    x2="-11.3603"
                    y2="52.8037"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#1341B0" />
                    <stop offset="1" stopColor="#3AC0C1" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_11_17376"
                    x1="37.0875"
                    y1="-20.0496"
                    x2="-11.3603"
                    y2="52.8037"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#1341B0" />
                    <stop offset="1" stopColor="#3AC0C1" />
                </linearGradient>
                <linearGradient
                    id="paint3_linear_11_17376"
                    x1="37.0875"
                    y1="-20.0496"
                    x2="-11.3603"
                    y2="52.8037"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#1341B0" />
                    <stop offset="1" stopColor="#3AC0C1" />
                </linearGradient>
                <linearGradient
                    id="paint4_linear_11_17376"
                    x1="37.0875"
                    y1="-20.0496"
                    x2="-11.3603"
                    y2="52.8037"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#1341B0" />
                    <stop offset="1" stopColor="#3AC0C1" />
                </linearGradient>
                <linearGradient
                    id="paint5_linear_11_17376"
                    x1="37.0875"
                    y1="-20.0496"
                    x2="-11.3603"
                    y2="52.8037"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#1341B0" />
                    <stop offset="1" stopColor="#3AC0C1" />
                </linearGradient>
                <linearGradient
                    id="paint6_linear_11_17376"
                    x1="37.0875"
                    y1="-20.0496"
                    x2="-11.3603"
                    y2="52.8037"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#1341B0" />
                    <stop offset="1" stopColor="#3AC0C1" />
                </linearGradient>
                <linearGradient
                    id="paint7_linear_11_17376"
                    x1="37.0875"
                    y1="-20.0496"
                    x2="-11.3603"
                    y2="52.8037"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#1341B0" />
                    <stop offset="1" stopColor="#3AC0C1" />
                </linearGradient>
                <linearGradient
                    id="paint8_linear_11_17376"
                    x1="37.0875"
                    y1="-20.0496"
                    x2="-11.3603"
                    y2="52.8037"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#1341B0" />
                    <stop offset="1" stopColor="#3AC0C1" />
                </linearGradient>
                <linearGradient
                    id="paint9_linear_11_17376"
                    x1="37.0875"
                    y1="-20.0496"
                    x2="-11.3603"
                    y2="52.8037"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#1341B0" />
                    <stop offset="1" stopColor="#3AC0C1" />
                </linearGradient>
                <linearGradient
                    id="paint10_linear_11_17376"
                    x1="37.0875"
                    y1="-20.0496"
                    x2="-11.3603"
                    y2="52.8037"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#1341B0" />
                    <stop offset="1" stopColor="#3AC0C1" />
                </linearGradient>
                <linearGradient
                    id="paint11_linear_11_17376"
                    x1="37.0875"
                    y1="-20.0496"
                    x2="-11.3603"
                    y2="52.8037"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#1341B0" />
                    <stop offset="1" stopColor="#3AC0C1" />
                </linearGradient>
                <clipPath id="clip0_11_17376">
                    <rect width="43" height="60" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default SpecialPriceIcon;
