import { IconProps } from './types';

export const ProjectIcon = ({ isWhiteColor }: IconProps) => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
                <path
                    d="M58.3774 40.7375C58.1853 40.3915 57.7494 40.2663 57.4025 40.4584C57.0565 40.6506 56.9313 41.0864 57.1234 41.4333C58.0668 43.1337 58.5657 45.0672 58.5657 47.0237C58.5657 53.3883 53.3882 58.5658 47.0236 58.5658C40.659 58.5658 35.4815 53.3883 35.4815 47.0237C35.4815 40.6592 40.659 35.4817 47.0236 35.4817C50.2245 35.4817 53.2009 36.7615 55.4039 39.0869C55.6763 39.3746 56.1294 39.3861 56.4171 39.1146C56.7038 38.8422 56.7162 38.3892 56.4448 38.1015C53.9683 35.4884 50.6231 34.049 47.0246 34.049C39.8696 34.049 34.0488 39.8697 34.0488 47.0247C34.0488 54.1797 39.8696 60.0004 47.0246 60.0004C54.1796 60.0004 60.0003 54.1797 60.0003 47.0247C60.0003 44.8245 59.4393 42.651 58.3783 40.7385L58.3774 40.7375Z"
                    fill={isWhiteColor ? 'white' : "url(#paint0_linear_19_11704)"}
                />
                <path
                    d="M48.5557 52.3379C48.9514 52.3379 49.2726 52.0168 49.2726 51.6211V46.6405C49.2726 45.1886 48.0922 44.0082 46.6403 44.0082C45.8862 44.0082 45.2048 44.3275 44.7249 44.8378C44.2451 44.3275 43.5637 44.0082 42.8096 44.0082C42.3355 44.0082 41.891 44.1344 41.5068 44.3542C41.3816 44.1468 41.1541 44.0082 40.8942 44.0082C40.4985 44.0082 40.1773 44.3294 40.1773 44.7251V51.6201C40.1773 52.0158 40.4985 52.3369 40.8942 52.3369C41.2899 52.3369 41.611 52.0158 41.611 51.6201V46.6395C41.611 45.9791 42.1482 45.4409 42.8096 45.4409C43.471 45.4409 44.0081 45.9781 44.0081 46.6395V51.6201C44.0081 52.0158 44.3293 52.3369 44.7249 52.3369C45.1206 52.3369 45.4418 52.0158 45.4418 51.6201V46.6395C45.4418 45.9791 45.9789 45.4409 46.6403 45.4409C47.3017 45.4409 47.8389 45.9781 47.8389 46.6395V51.6201C47.8389 52.0158 48.16 52.3369 48.5557 52.3369V52.3379Z"
                    fill={isWhiteColor ? 'white' : "url(#paint1_linear_19_11704)"}
                />
                <path
                    d="M50.3478 45.7506C50.1433 45.9561 50.0812 46.2639 50.193 46.5325C50.3039 46.8001 50.5648 46.975 50.8554 46.975H53.9196C54.3153 46.975 54.6364 46.6538 54.6364 46.2582C54.6364 45.8625 54.3153 45.5413 53.9196 45.5413H52.5853L53.7323 44.3944C54.321 43.8056 54.4893 42.9607 54.171 42.1913C53.8527 41.4219 53.1368 40.944 52.3034 40.944C51.1096 40.944 50.1385 41.9151 50.1385 43.1088V43.1929C50.1385 43.5886 50.4597 43.9098 50.8554 43.9098C51.2511 43.9098 51.5722 43.5886 51.5722 43.1929V43.1088C51.5722 42.7055 51.9 42.3777 52.3034 42.3777C52.6675 42.3777 52.8109 42.6558 52.8463 42.7399C52.8816 42.825 52.9762 43.1222 52.7191 43.3803L50.3488 45.7506H50.3478Z"
                    fill={isWhiteColor ? 'white' : "url(#paint2_linear_19_11704)"}
                />
                <path
                    d="M0.937362 46.9597H26.986C27.5034 46.9597 27.9234 46.5398 27.9234 46.0223C27.9234 45.5049 27.5034 45.085 26.986 45.085H17.9049V29.9922C17.9049 29.4748 17.4849 29.0548 16.9675 29.0548C16.4501 29.0548 16.0301 29.4748 16.0301 29.9922V45.085H1.87472V4.88091H4.94427C5.08925 4.88091 5.23298 4.84717 5.36421 4.78218L11.3758 1.77637C11.8395 1.54391 12.027 0.98149 11.7958 0.519058C11.5645 0.0553765 11.0009 -0.132096 10.5384 0.09912L4.72305 3.00619H0.937362C0.419938 3.00619 0 3.42613 0 3.94355V46.0223C0 46.5398 0.419938 46.9597 0.937362 46.9597Z"
                    fill={isWhiteColor ? 'white' : "url(#paint3_linear_19_11704)"}
                />
                <path
                    d="M46.022 27.9238C46.5394 27.9238 46.9593 27.5038 46.9593 26.9864V11.9586C46.9593 11.4412 46.5394 11.0213 46.022 11.0213C45.5045 11.0213 45.0846 11.4412 45.0846 11.9586V18.034H36.0034C35.486 18.034 35.0661 18.4539 35.0661 18.9713C35.0661 19.4888 35.486 19.9087 36.0034 19.9087H45.0846V26.9852C45.0846 27.5026 45.5045 27.9225 46.022 27.9225V27.9238Z"
                    fill={isWhiteColor ? 'white' : "url(#paint4_linear_19_11704)"}
                />
                <path
                    d="M34.7311 14.3783C34.4074 13.9746 33.8188 13.9083 33.4138 14.232L28.6608 18.034H17.9686C17.9473 18.034 17.9261 18.0352 17.9036 18.0365V4.88092H45.0833V7.95046C45.0833 8.46789 45.5033 8.88783 46.0207 8.88783C46.5381 8.88783 46.9581 8.46789 46.9581 7.95046V3.94356C46.9581 3.42613 46.5381 3.00619 46.0207 3.00619H12.9606C12.4432 3.00619 12.0232 3.42613 12.0232 3.94356C12.0232 4.46098 12.4432 4.88092 12.9606 4.88092H16.0301V22.9795C16.0301 23.192 16.1026 23.3994 16.2351 23.5644L20.2433 28.5737C20.4282 28.8049 20.7007 28.9261 20.9756 28.9261C21.1806 28.9261 21.3881 28.8586 21.5606 28.7211C21.9643 28.3974 22.0305 27.8075 21.7068 27.4038L17.9049 22.6508V19.9062C17.9261 19.9074 17.9473 19.9087 17.9698 19.9087H28.9907C29.2032 19.9087 29.4107 19.8362 29.5756 19.7037L34.5849 15.6956C34.9898 15.3719 35.0548 14.7832 34.7311 14.3783Z"
                    fill={isWhiteColor ? 'white' : "url(#paint5_linear_19_11704)"}
                />
                <path
                    d="M26.986 51.0953H4.03315L4.46309 50.8091C4.89428 50.5217 5.01051 49.9405 4.72305 49.5093C4.4356 49.0781 3.85443 48.9619 3.42324 49.2494L0.417438 51.2528C0.156227 51.4265 0 51.719 0 52.0327C0 52.3464 0.156227 52.6389 0.417438 52.8126L3.42324 54.816C3.58322 54.9235 3.76444 54.9735 3.94317 54.9735C4.24562 54.9735 4.54308 54.8273 4.7243 54.5561C5.01176 54.1249 4.89553 53.5437 4.46434 53.2563L4.0344 52.9701H26.986C27.5034 52.9701 27.9234 52.5501 27.9234 52.0327C27.9234 51.5153 27.5034 51.0953 26.986 51.0953Z"
                    fill={isWhiteColor ? 'white' : "url(#paint6_linear_19_11704)"}
                />
                <path
                    d="M49.5089 7.72925C49.9401 8.0167 50.5225 7.90047 50.8088 7.46929L51.095 7.03935V27.9888C51.095 28.5062 51.5149 28.9261 52.0323 28.9261C52.5497 28.9261 52.9697 28.5062 52.9697 27.9888V7.03935L53.2559 7.46929C53.4371 7.7405 53.7333 7.88672 54.037 7.88672C54.2157 7.88672 54.397 7.83548 54.5569 7.72925C54.9881 7.44179 55.1044 6.85938 54.8169 6.42944L52.8135 3.42363C52.6397 3.16242 52.3473 3.00619 52.0336 3.00619C51.7199 3.00619 51.4274 3.16242 51.2537 3.42363L49.2502 6.42944C48.9628 6.86062 49.079 7.44304 49.5102 7.72925H49.5089Z"
                    fill={isWhiteColor ? 'white' : "url(#paint7_linear_19_11704)"}
                />
                <defs>
                    <linearGradient
                        id="paint0_linear_19_11704"
                        x1="56.432"
                        y1="25.3768"
                        x2="38.7814"
                        y2="62.4115"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#1341B0" />
                        <stop offset="1" stopColor="#3AC0C1" />
                    </linearGradient>
                    <linearGradient
                        id="paint1_linear_19_11704"
                        x1="56.432"
                        y1="25.3768"
                        x2="38.7814"
                        y2="62.4115"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#1341B0" />
                        <stop offset="1" stopColor="#3AC0C1" />
                    </linearGradient>
                    <linearGradient
                        id="paint2_linear_19_11704"
                        x1="56.432"
                        y1="25.3768"
                        x2="38.7814"
                        y2="62.4115"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#1341B0" />
                        <stop offset="1" stopColor="#3AC0C1" />
                    </linearGradient>
                    <linearGradient
                        id="paint3_linear_19_11704"
                        x1="47.4157"
                        y1="-18.3702"
                        x2="10.0268"
                        y2="60.0816"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#1341B0" />
                        <stop offset="1" stopColor="#3AC0C1" />
                    </linearGradient>
                    <linearGradient
                        id="paint4_linear_19_11704"
                        x1="47.4157"
                        y1="-18.3702"
                        x2="10.0268"
                        y2="60.0816"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#1341B0" />
                        <stop offset="1" stopColor="#3AC0C1" />
                    </linearGradient>
                    <linearGradient
                        id="paint5_linear_19_11704"
                        x1="47.4157"
                        y1="-18.3702"
                        x2="10.0268"
                        y2="60.0816"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#1341B0" />
                        <stop offset="1" stopColor="#3AC0C1" />
                    </linearGradient>
                    <linearGradient
                        id="paint6_linear_19_11704"
                        x1="47.4157"
                        y1="-18.3702"
                        x2="10.0268"
                        y2="60.0816"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#1341B0" />
                        <stop offset="1" stopColor="#3AC0C1" />
                    </linearGradient>
                    <linearGradient
                        id="paint7_linear_19_11704"
                        x1="47.4157"
                        y1="-18.3702"
                        x2="10.0268"
                        y2="60.0816"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#1341B0" />
                        <stop offset="1" stopColor="#3AC0C1" />
                    </linearGradient>
                </defs>
            </svg>
        </>
    );
};
